import { gql } from '@apollo/client';
import {
  Button,
  Checkbox,
  Form,
  Header,
  Input,
  Space,
  toCapitalizeCase,
  useMutation,
  useRootStore,
  useTranslation,
  Widget,
  Write,
} from '@gimlite/watermelon';
import IEM from '@gimlite/watermelon/assets/logo/IEM_small';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Mutation,
  MutationLoginArgs,
  MutationResetPasswordArgs,
} from '../../../../client/graphql';
import LoginJPG from '../../../assets/images/login.jpg';
import './login.page.scss';

export const LoginPage = observer(() => {
  const navigate = useNavigate();
  const { AuthStore } = useRootStore();
  const { t } = useTranslation();
  const [currentError, setCurrentError] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<{
    username: string | null;
    password: string | null;
  }>({
    username: null,
    password: null,
  });

  const [currentAdditionalUser, setCurrentAdditionalUser] = useState<{
    newPassword: string | null;
    confirmNewPassword: string | null;
    acceptContract: boolean;
  }>({
    newPassword: null,
    confirmNewPassword: null,
    acceptContract: false,
  });
  const [step, setStep] = useState(0);

  const [loginCall, loginState] = useMutation<
    { login: Mutation['login'] },
    MutationLoginArgs
  >(gql`
    mutation login($username: String!, $password: String!) {
      login(username: $username, password: $password) {
        auth {
          token
          status
        }
        user {
          state
        }
      }
    }
  `);

  const [resetPasswordCall, resetPasswordState] = useMutation<
    { resetPassword: Mutation['resetPassword'] },
    MutationResetPasswordArgs
  >(gql`
    mutation resetPassword(
      $username: String!
      $currentPassword: String!
      $newPassword: String!
    ) {
      resetPassword(
        username: $username
        currentPassword: $currentPassword
        newPassword: $newPassword
      ) {
        auth {
          token
          status
        }
        user {
          state
        }
      }
    }
  `);

  useEffect(() => {
    AuthStore.login();
  }, []);

  useEffect(() => {
    if (loginState.data) {
      const { token, status } = loginState.data.login.auth;

      if (status === 'PASSWORD_CHANGE_REQUESTED') {
        setStep(1);
      } else if (token) {
        AuthStore.login(token);
        navigate('/');
      }
    }
  }, [loginState.data]);

  useEffect(() => {
    if (resetPasswordState.data) {
      const { token } = resetPasswordState.data.resetPassword.auth;

      if (token) {
        AuthStore.login(token);
        navigate('/');
      }
    }
  }, [resetPasswordState.data, resetPasswordState.error]);

  useEffect(() => {
    if (loginState.error) {
      setCurrentError('Identifiant ou mot de passe incorrect');
    }
  }, [loginState.data, loginState.error]);

  return (
    <div className="login">
      {/*! Lors d'une refact du router, ce code apparaitra une fois en global (PROBLEM 1) }*/}
      {/******* START ************/}
      <Header config={{ logo: IEM(), position: 'absolute' }} />
      {/******* STOP ************/}
      <div className="login__back">
        <div className="login__back__mask"></div>
        <img className="login__back__image" src={LoginJPG} alt="" />
      </div>
      <Widget
        className="loginWidget"
        config={{
          responsive: {
            padding: false,
          },
          icon: 'faUserSolid',
          position: 'absolute',
          title: step === 0 ? t('personalSpace') : t('changeYourPassword'),
        }}
      >
        <Form
          className="loginForm"
          config={{ validateOnChange: false }}
          handleEvent={{
            change: (value: Record<string, string | number>) => {
              if (step === 0) {
                setCurrentUser({
                  password: value?.password as string | null,
                  username: value?.username as string | null,
                });
              }

              if (step === 1) {
                setCurrentAdditionalUser({
                  newPassword: value?.newPassword as string | null,
                  confirmNewPassword: value?.confirmNewPassword as
                    | string
                    | null,
                  acceptContract: !!value?.acceptContract || false,
                });
              }

              setCurrentError(null);
            },
          }}
        >
          {step === 0 ? (
            <>
              <Space config={{ way: 'vertical', count: 1.5 }} />
              <Form.Item
                config={{
                  name: 'username',
                  label: `${toCapitalizeCase(t('email'))}`,
                  way: 'vertical',

                  rules: [{ required: true }],
                }}
              >
                <Input
                  className="loginFormInput"
                  config={{
                    height: 'large',
                    width: 'full',
                    placeholder: t('emailAddress'),
                  }}
                />
              </Form.Item>
              <Space config={{ way: 'vertical', count: 1.5 }} />
              <Form.Item
                config={{
                  name: 'password',
                  label: t('password'),
                  way: 'vertical',
                  rules: [{ required: true }],
                }}
              >
                <Input
                  className="loginFormInput"
                  config={{
                    type: { name: 'password' },
                    width: 'full',
                    height: 'large',
                    placeholder: t('password'),
                  }}
                />
              </Form.Item>
              <Space config={{ way: 'vertical', count: 2 }} />
              <Button
                handleEvent={{
                  click: async () => {
                    if (currentUser.username && currentUser.password) {
                      try {
                        await loginCall({
                          variables: {
                            username: currentUser.username,
                            password: currentUser.password,
                          },
                        });
                      } catch (error) {}
                    }
                  },
                }}
                config={{ color: 'primary', text: t('signIn') }}
              />
              <Space config={{ count: 1 }} />
              {currentError && (
                <Write
                  data={{ item: t(currentError) }}
                  config={{
                    color: 'error',
                    mode: 'key-small-light',
                  }}
                />
              )}
            </>
          ) : undefined}
          {step === 1 ? (
            <>
              <Space config={{ way: 'vertical', count: 1.5 }} />
              <Form.Item
                config={{
                  name: 'newPassword',
                  label: t('newPassword'),
                  way: 'vertical',
                  rules: [{ required: true }],
                }}
              >
                <Input
                  className="loginFormInput"
                  config={{
                    type: { name: 'password' },
                    width: 'full',
                    height: 'large',
                    placeholder: t('newPassword'),
                  }}
                />
              </Form.Item>
              <Space config={{ way: 'vertical', count: 1.5 }} />
              <Form.Item
                config={{
                  name: 'confirmNewPassword',
                  label: t('confirmationOfNewPassword'),
                  way: 'vertical',
                  rules: [{ required: true }],
                }}
              >
                <Input
                  className="loginFormInput"
                  config={{
                    type: { name: 'password' },
                    width: 'full',
                    height: 'large',
                    placeholder: t('confirmationOfNewPassword'),
                  }}
                />
              </Form.Item>
              <Space config={{ way: 'vertical', count: 1 }} />
              <div className="loginFormPart">
                <Write
                  data={{ item: `* ${t('requiredFields')}` }}
                  config={{
                    color: 'error',
                    mode: 'value-small',
                  }}
                />
              </div>
              <Space config={{ way: 'vertical', count: 2 }} />
              <div className="loginFormPart">
                <Write
                  data={{ item: t('INFO_ABOUT_PERSONAL_INFO') }}
                  config={{
                    mode: 'value-small',
                  }}
                />
              </div>
              <Space config={{ way: 'vertical', count: 2 }} />

              <div className="loginFormPart">
                <Form.Item
                  className="loginFormCheckbox"
                  config={{
                    name: 'acceptContract',
                    rules: [{ required: true }],
                  }}
                >
                  <Checkbox />
                </Form.Item>
                <Space config={{ way: 'horizontal', count: 1.5 }} />
                <Write
                  className="loginFormText"
                  data={{ item: t('CONFIRM_INFO_ABOUT_PERSONAL_INFO') }}
                  config={{
                    mode: 'value-small',
                  }}
                />
              </div>

              <Space config={{ way: 'vertical', count: 2 }} />
              <Button
                handleEvent={{
                  click: async () => {
                    if (
                      !currentAdditionalUser?.newPassword ||
                      !currentAdditionalUser?.confirmNewPassword
                    ) {
                      setCurrentError('fillNewPasswordAndConfirm');
                    } else if (currentAdditionalUser?.newPassword?.length < 6) {
                      setCurrentError('passwordTooShort');
                    } else if (
                      currentAdditionalUser?.newPassword !==
                      currentAdditionalUser?.confirmNewPassword
                    ) {
                      setCurrentError('identicalPassword');
                    } else if (!currentAdditionalUser?.acceptContract) {
                      setCurrentError('acceptContract');
                    } else {
                      setCurrentError(null);
                      await resetPasswordCall({
                        variables: {
                          username: currentUser.username as string,
                          currentPassword: currentUser.password as string,
                          newPassword:
                            currentAdditionalUser.newPassword as string,
                        },
                      });
                    }
                  },
                }}
                config={{ color: 'primary', text: t('confirm') }}
              />
              <Space config={{ way: 'vertical', count: 1 }} />

              {currentError && (
                <Write
                  data={{ item: t(currentError) }}
                  config={{
                    color: 'error',
                    mode: 'key-small-light',
                  }}
                />
              )}
            </>
          ) : undefined}
        </Form>
      </Widget>
    </div>
  );
});
