import { gql } from '@apollo/client';
import {
  Color,
  Form,
  Row,
  useLazyQuery,
  useQuery,
  useRootStore,
} from '@gimlite/watermelon';
import { Badge } from '@gimlite/watermelon/components/badge/badge.component';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { Col } from '@gimlite/watermelon/components/col/col.component';
import {
  DatePicker,
  DatePickerType,
} from '@gimlite/watermelon/components/datepicker/datepicker.component';
import { Description } from '@gimlite/watermelon/components/description/description.component';
import { Empty } from '@gimlite/watermelon/components/empty/empty.component';
import { FilterExpanded } from '@gimlite/watermelon/components/filter/filter.component';
import {
  Icon,
  IconType,
} from '@gimlite/watermelon/components/icon/icon.component';
import { Image } from '@gimlite/watermelon/components/image/image.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Popover } from '@gimlite/watermelon/components/popover/popover.component';
import { Select } from '@gimlite/watermelon/components/select/select.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import {
  endOfDay,
  lastMonth,
  lastWeek,
  yesterday,
} from '@gimlite/watermelon/functions/date.function';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Query,
  QueryCamerasArgs,
  QueryEventArgs,
  QueryEventsArgs,
} from '../../../../client/graphql';
import './events.page.scss';

const wayFormatted = (
  way: 'exit' | 'entry' | 'unknown',
): {
  text: string;
  color: Extract<Color, 'warn' | 'success' | 'disabled'>;
  icon: IconType.Config.Type;
} => {
  switch (way) {
    case 'exit':
      return {
        text: `exit`,
        color: 'warn',
        icon: 'faArrowRightFromBracketSolid',
      };

    case 'entry':
      return {
        text: `entry`,
        color: 'success',
        icon: 'faArrowRightToBracketSolid',
      };

    case 'unknown':
      return {
        text: `unknown-f`,
        color: 'disabled',
        icon: 'faCircleQuestionSolid',
      };

    default:
      return {
        text: `undefined`,
        color: 'disabled',
        icon: 'faQuestionSolid',
      };
  }
};

export const Events = observer(() => {
  const { getParamsUrl, setParamsUrl, clearParamsUrl } = useMyUrl();
  const { AuthStore } = useRootStore();
  const [filterValue, setFilterValue] = useState<Object>({});
  const { lang, t } = useTranslation();
  const { id } = useParams();
  const me = AuthStore.user! as Query['me'];
  const defaultStartDate = yesterday('DATE');
  const defaultEndDate = endOfDay('DATE');

  const camerasQuery = useQuery<
    { cameras: Query['cameras'] },
    QueryCamerasArgs
  >(
    gql`
      query cameras($upsId: String!) {
        cameras(upsId: $upsId) {
          _id
          code
          ups
          position
        }
      }
    `,
    {
      variables: {
        upsId: id!,
      },
    },
  );

  const [eventsCall, eventsState] = useLazyQuery<
    { events: Query['events'] },
    QueryEventsArgs
  >(gql`
    query events(
      $limit: Int
      $page: Int
      $order: String
      $startDate: String
      $endDate: String
      $way: String
      $upsId: String!
      $lpnLike: String
      $cameraId: String
      $isVehicle: Boolean
      $knownLpn: Boolean
    ) {
      events(
        limit: $limit
        page: $page
        order: $order
        startDate: $startDate
        endDate: $endDate
        upsId: $upsId
        way: $way
        lpnLike: $lpnLike
        cameraId: $cameraId
        isVehicle: $isVehicle
        knownLpn: $knownLpn
      ) {
        list {
          _id
          way
          confidence
          happenedAt
          hasSubscription
          hasParkingRight
          knownLpn
          isAnonymised
          vehicle {
            lpn
          }
          resources {
            list {
              url
            }
            paging {
              current
              count
              limit
            }
          }
        }
        paging {
          current
          count
          limit
        }
      }
    }
  `);

  const [eventCall, eventState] = useLazyQuery<
    { event: Query['event'] },
    QueryEventArgs
  >(gql`
    query event($cameraEventId: String!) {
      event(cameraEventId: $cameraEventId) {
        _id
        way
        confidence
        happenedAt
        vehicle {
          lpn
          type
        }
        camera {
          code
          ups
          position
        }
        resources {
          list {
            url
          }
          paging {
            current
            count
            limit
          }
        }
        createdAt
      }
    }
  `);

  const events = useMemo(() => {
    if (!eventsState.data) return undefined;

    const {
      list,
      paging: { current, count, limit },
    } = eventsState.data?.events;

    const listFormatted = list.map(
      ({
        _id,
        happenedAt,
        way,
        vehicle,
        hasSubscription,
        hasParkingRight,
        knownLpn,
        isAnonymised,
      }) => ({
        _id,
        credential: (
          <Row>
            {hasSubscription && (
              <Icon
                config={{
                  type: 'faCalendarCheckSolid',
                  color: 'primary',
                  size: 'small',
                }}
              />
            )}

            {!hasSubscription && hasParkingRight && (
              <Icon
                config={{
                  type: 'faClockSolid',
                  color: 'primary',
                  size: 'small',
                }}
              />
            )}
          </Row>
        ),
        happenedAt: happenedAt
          ? DateTime.fromISO(happenedAt).toFormat('dd/MM/yy HH:mm:ss')
          : null,
        way,
        lpn: isAnonymised ? (
          <Write
            data={{ item: vehicle?.lpn?.toUpperCase() || '' }}
            config={{ mode: 'cell-value', color: 'disabled' }}
          />
        ) : (
          <Write
            data={{ item: vehicle?.lpn?.toUpperCase() || '' }}
            config={{ mode: 'cell-value', color: 'text' }}
          />
        ),
      }),
    );

    return {
      list: listFormatted,
      paging: { current, count, limit },
    };
  }, [eventsState.data]);

  const event = useMemo(() => {
    if (!eventState.data) return undefined;

    const { _id, happenedAt, way, vehicle, resources, camera, createdAt } =
      eventState.data?.event;

    return {
      _id,
      lpn: vehicle?.lpn?.toUpperCase() || '',
      happenedAt: happenedAt
        ? DateTime.fromISO(happenedAt).toFormat('dd/MM/yy HH:mm:ss')
        : null,
      image: {
        item: resources?.list[0]?.url,
      },
      recognized: [vehicle?.type, 'nonmotorVehicle'],
      createdAt: createdAt
        ? DateTime.fromISO(createdAt).toFormat('dd/MM/yy HH:mm:ss')
        : null,
      name: camera?.code,
      way,
    };
  }, [eventState.data]);

  const cameras = useMemo(() => {
    if (!camerasQuery.data) return undefined;

    return camerasQuery.data?.cameras?.map(({ _id, code }) => ({
      value: _id,
      label: code,
    }));
  }, [camerasQuery.data]);

  const filterValueProxy = useMemo(() => {
    if (Object.values(getParamsUrl).length > 0) return getParamsUrl;
    return {
      cameraId: 'all',
      way: 'all',
      isVehicle: 'all',
      knownLpn: 'yes',
      lpnLike: '',
      startDate: defaultStartDate,
      endDate: defaultEndDate,
      ...filterValue,
    };
  }, [getParamsUrl, filterValue, defaultStartDate, defaultEndDate]);

  const deletionDelayExist = useMemo(() => {
    const findUps = me?.ups?.find((ups) => ups?._id === id);
    return !!findUps?.config?.prestoScan?.deletionDelay;
  }, [me, id]);

  const [interneContextReactive, setInterneContextReactive] = useState<
    Record<string, string>
  >({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  });

  const endDateLimit = useMemo((): {
    min: DatePickerType.Config.Min | null;
    max: DatePickerType.Config.Max | null;
  } => {
    return {
      min: interneContextReactive.startDate || new Date().toISOString(),
      max: null,
    };
  }, [interneContextReactive.startDate]);

  const startDateLimit = useMemo((): {
    min: DatePickerType.Config.Min | null;
    max: DatePickerType.Config.Max | null;
  } => {
    return {
      min: null,
      max: interneContextReactive.endDate,
    };
  }, [interneContextReactive.endDate]);

  const clearFilterValueProxy = useCallback(() => {
    clearParamsUrl();
    setFilterValue({});
  }, []);

  const formattedRetention = useCallback(
    (text: string, key?: string) => {
      const findUps: any = me?.ups?.find((ups: any) => ups?._id === id);
      if (!key || !findUps) return <></>;
      const time = findUps?.config?.prestoScan?.deletionDelay?.[key];
      if (!time) return <></>;

      const formattedDays = (time > 1 ? t('days') : t('day')).toLowerCase();
      const translatedText = t(text)
        .replace('[time]', `${time} ${formattedDays}`)
        .replace(/[\<\>]/g, '')
        .replace(/«([^»]*)»/g, '«<strong>$1</strong>»');

      return (
        <li
          dangerouslySetInnerHTML={{
            __html: translatedText,
          }}
        ></li>
      );
    },
    [lang, me, id],
  );

  const searchEvents = (searchRequest: any, getParamsUrl: any) => {
    const deleteTypeAllInKeys = ['way', 'cameraId', 'isVehicle', 'knownLpn'];
    const keysToDelete = Object.keys(searchRequest).filter(
      (key) =>
        deleteTypeAllInKeys.includes(key) && searchRequest[key] === 'all',
    );
    keysToDelete.forEach((key) => {
      delete searchRequest[key];
    });

    const stringToBoolean = ['isVehicle', 'knownLpn'];

    Object.entries(getParamsUrl as Object)
      .filter(
        ([key, value]): boolean =>
          stringToBoolean.includes(key) && ['yes', 'no'].includes(value),
      )
      .map(([key, value]) => (searchRequest[key] = value === 'yes'));

    eventsCall({ variables: searchRequest });
  };

  useEffect(() => {
    if (getParamsUrl?.startDate && getParamsUrl?.endDate) {
      setInterneContextReactive(() => ({
        startDate: getParamsUrl.startDate,
        endDate: getParamsUrl.endDate,
      }));
    }
  }, [getParamsUrl]);

  useEffect(() => {
    searchEvents({ limit: 50, ...getParamsUrl, upsId: id }, getParamsUrl);
  }, [getParamsUrl, cameras, id]);

  useEffect(() => {
    eventState.resetData();
  }, [window.location.pathname]);

  return (
    <Page className="events">
      <Widget.Group
        state={{
          error: eventsState.error || eventState.error,
          loading: eventsState.loading || eventState.loading,
          refetch: () => {
            eventsState.refetch?.();

            if (eventState.data) {
              eventState.refetch?.();
            }
          },
        }}
        config={{
          icon: 'faCarSolid',
          title: `${t('entries/exits')} (${events?.paging?.count ?? 0} ${t(
            events?.paging?.count && events?.paging?.count > 1
              ? 'events'
              : 'event',
          ).toLowerCase()})`,
          extra: (
            <Popover
              data={
                <div className="durationInfo">
                  <span className="durationInfoTitle">
                    {t('retentionTimes')}
                  </span>
                  {deletionDelayExist ? (
                    <ul className="durationInfoList">
                      {formattedRetention('RETENTION_COMPLETE', 'complete')}
                      {formattedRetention('RETENTION_INCOMPLETE', 'incomplete')}
                      {formattedRetention('RETENTION_CHECKED', 'toCheck')}
                      {formattedRetention('RETENTION_NOT_FINED', 'unfined')}
                      {formattedRetention('RETENTION_FINED', 'fined')}
                    </ul>
                  ) : (
                    <div className="durationInfoEmpty">
                      <Empty />
                    </div>
                  )}
                </div>
              }
              config={{
                placement: 'bottom',
              }}
            >
              <div className="eventsDuration">
                <Icon
                  className="eventsDurationIcon"
                  config={{ color: 'primary', type: 'faCircleInfoSolid' }}
                ></Icon>
                <Write
                  data={{ item: t('seeRetentionTimes') }}
                  config={{
                    mode: 'link',
                    color: 'primary',
                  }}
                />
              </div>
            </Popover>
          ),
        }}
      >
        <Zone
          config={{
            gap: {
              y: 1,
              x: 2,
            },
            zones: [
              ['filter', 'filter'],
              ['nav', 'camera'],
              ['nav', 'description'],
            ],
            rows: ['min-content', '1fr', 'min-content'],
            columns: ['minmax(450px, 20vw)', '1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <Widget>
              <FilterExpanded
                data={{
                  value: filterValueProxy,
                }}
                handleEvent={{
                  change: (value) => {
                    setFilterValue(() => value);
                    setInterneContextReactive(() => value);
                  },
                  submit: (data: any) => {
                    setParamsUrl({
                      ...getParamsUrl,
                      ...data,
                      upsId: id,
                      page: 1,
                    });
                  },
                }}
              >
                <FilterExpanded.Fields>
                  <Form.Item
                    config={{
                      name: 'startDate',
                      label: t('startDate'),
                      way: 'vertical',
                      rules: [],
                    }}
                  >
                    <DatePicker
                      config={{
                        width: 'medium',
                        min: startDateLimit.min,
                        max: startDateLimit.max,
                        presets: [
                          {
                            label: t(yesterday('LABEL')),
                            value: yesterday('DATE'),
                          },
                          {
                            label: t(lastWeek('LABEL')),
                            value: lastWeek('DATE'),
                          },
                          {
                            label: t(lastMonth('LABEL')),
                            value: lastMonth('DATE'),
                          },
                        ],
                        format: 'datetime',
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    config={{
                      name: 'endDate',
                      label: t('endDate'),
                      way: 'vertical',
                      rules: [],
                    }}
                  >
                    <DatePicker
                      config={{
                        width: 'medium',
                        min: endDateLimit.min,
                        max: endDateLimit.max,
                        format: 'datetime',
                        presets: [
                          {
                            label: t(endOfDay('LABEL')),
                            value: endOfDay('DATE'),
                          },
                        ],
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    config={{
                      name: 'way',
                      label: t('direction'),
                      way: 'vertical',
                      rules: [],
                    }}
                  >
                    <Select
                      config={{ width: 'xmedium', clear: false }}
                      data={{
                        items: [
                          {
                            label: t('all-f'),
                            value: 'all',
                          },
                          {
                            label: t('entries'),
                            value: 'entry',
                          },
                          {
                            label: t('exits'),
                            value: 'exit',
                          },
                          {
                            label: t('unknowns-f'),
                            value: 'unknown',
                          },
                        ],
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    config={{
                      name: 'lpnLike',
                      label: t('immatriculation'),
                      way: 'vertical',
                      rules: [],
                    }}
                  >
                    <Input
                      config={{ width: 'xmedium', placeholder: 'GG777RR' }}
                    />
                  </Form.Item>
                  <Form.Item
                    config={{
                      name: 'cameraId',
                      label: t('camera'),
                      way: 'vertical',
                      rules: [],
                    }}
                  >
                    <Select
                      data={{
                        items: [
                          {
                            label: t('all-f'),
                            value: 'all',
                          },

                          cameras ? cameras : [],
                        ].flat(),
                      }}
                      config={{ width: 'xmedium', clear: false }}
                    />
                  </Form.Item>
                  <Form.Item
                    config={{
                      name: 'isVehicle',
                      label: t('passageType'),
                      way: 'vertical',
                      rules: [],
                    }}
                  >
                    <Select
                      data={{
                        items: [
                          {
                            label: t('all-m'),
                            value: 'all',
                          },
                          {
                            label: t('4WheelVehicles'),
                            value: 'yes',
                          },
                          {
                            label: t('noVehicles'),
                            value: 'no',
                          },
                        ],
                      }}
                      config={{ width: 'xmedium', clear: false }}
                    />
                  </Form.Item>
                  <Form.Item
                    config={{
                      name: 'knownLpn',
                      label: t('LAPI'),
                      way: 'vertical',
                      rules: [],
                    }}
                  >
                    <Select
                      data={{
                        items: [
                          {
                            label: t('all-f'),
                            value: 'all',
                          },
                          {
                            label: t('onlyRecognised'),
                            value: 'yes',
                          },
                          {
                            label: t('onlyNotRecognised'),
                            value: 'no',
                          },
                        ],
                      }}
                      config={{ width: 'xmedium', clear: false }}
                    />
                  </Form.Item>
                </FilterExpanded.Fields>
                <FilterExpanded.Actions>
                  <Button
                    config={{
                      text: t('search'),
                      type: { value: 'submit' },
                      color: 'primary',
                      size: 'medium',
                    }}
                  />
                  <Button
                    handleEvent={{
                      click: () => {
                        clearFilterValueProxy();
                      },
                    }}
                    config={{
                      text: t('clear'),
                      type: { value: 'button' },
                      color: 'primary',
                      size: 'medium',
                    }}
                  />
                  {/* <Button
                      handleEvent={{
                        click: () => {
                          console.log('export');
                        },
                      }}
                      config={{
                        disabled: true,
                        text: t('export'),
                        color: 'primary',
                      }}
                    /> */}
                </FilterExpanded.Actions>
              </FilterExpanded>
              <Space config={{ way: 'vertical' }} />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'nav' }}>
            <Widget>
              <Table<any>
                handleEvent={{
                  render: (data) => {
                    return data.map(({ way, ...rest }) => {
                      const wayInfo = wayFormatted(way);

                      return {
                        ...rest,
                        way: (
                          <Badge
                            config={{
                              size: 'medium',
                              radius: true,
                              text: t(wayInfo?.text),
                              icon: wayInfo?.icon,
                              color: wayInfo?.color,
                            }}
                          ></Badge>
                        ),
                      };
                    });
                  },
                  paging: (value: any) => {
                    searchEvents(
                      {
                        upsId: id,
                        ...getParamsUrl,
                        ...value,
                      },
                      getParamsUrl,
                    );
                  },
                  read: (value: any) => {
                    const { _id } = value;

                    eventCall({ variables: { cameraEventId: _id } });
                  },
                }}
                data={
                  events
                    ? {
                        list: events?.list,
                        paging: events?.paging,
                      }
                    : undefined
                }
                config={{
                  alternateColor: true,
                  actions: {
                    read: true,
                  },
                  pagination: 'scroll',
                  columns: [
                    {
                      title: '',
                      key: 'credential',
                    },
                    {
                      title: t('date'),
                      key: 'happenedAt',
                    },
                    {
                      title: t('direction'),
                      key: 'way',
                    },
                    {
                      title: t('immatriculation'),
                      key: 'lpn',
                    },
                  ],
                }}
              />
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'camera' }}>
            <Widget>
              {event?.image ? (
                event?.image?.item ? (
                  <Image
                    data={{
                      src: event.image.item,
                    }}
                    config={{
                      preview: true,
                      size: 'full',
                    }}
                  />
                ) : (
                  <Image
                    config={{
                      size: 'full',
                      empty: {
                        icon: (
                          <Icon
                            config={{
                              size: {
                                maxHeight: '200px',
                                maxWidth: '200px',
                              },
                              type: 'customNoPhotography',
                              color: 'primary',
                            }}
                          />
                        ),
                        text: t('RETENTION_TIME_EXPIRED'),
                      },
                    }}
                  />
                )
              ) : (
                <Image
                  config={{
                    size: 'full',
                    preview: true,
                  }}
                />
              )}
              {event?.way === 'exit' && (
                <Badge
                  className="eventsImageBadge"
                  config={{
                    size: 'xlarge',
                    radius: false,
                    text: t(`exit`),
                    icon: 'faArrowRightFromBracketSolid',
                    color: 'warn',
                  }}
                ></Badge>
              )}

              {event?.way === 'entry' && (
                <Badge
                  className="eventsImageBadge"
                  config={{
                    size: 'xlarge',
                    radius: false,
                    text: t(`entry`),
                    icon: 'faArrowRightToBracketSolid',
                    color: 'success',
                  }}
                ></Badge>
              )}

              {event?.way === 'unknown' && (
                <Badge
                  className="eventsImageBadge"
                  config={{
                    size: 'large',
                    radius: false,
                    text: t(`unknown-f`),
                    icon: 'faCircleQuestionSolid',
                    color: 'disabled',
                  }}
                ></Badge>
              )}
            </Widget>
          </Zone.Area>

          <Zone.Area config={{ area: 'description' }}>
            <Widget>
              <Col>
                {event && (
                  <>
                    <Space config={{ way: 'vertical' }} />

                    <Description
                      data={[
                        {
                          key: 'immatriculation',
                          label: t('immatriculation'),
                          value: event?.lpn ?? null,
                        },
                        {
                          key: 'registeredOnTheServer',
                          label: t('registeredOnTheServer'),
                          value: event?.createdAt ?? null,
                        },
                        {
                          key: 'recognized',
                          label: t('recognizedVehicle'),
                          value:
                            event?.recognized &&
                            event.recognized[0] === event.recognized[1]
                              ? t('no')
                              : t('yes'),
                        },
                        {
                          key: 'way',
                          label:
                            event?.way === 'entry' ? t('entry') : t('exit'),
                          value: event?.happenedAt ?? null,
                        },
                        {
                          key: 'camera',
                          label: t('camera'),
                          value: event?.name ?? null,
                        },
                      ]}
                    />
                  </>
                )}
                <Space config={{ way: 'vertical' }} />
              </Col>
            </Widget>
          </Zone.Area>
        </Zone>
      </Widget.Group>
    </Page>
  );
});
